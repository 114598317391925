export const languageChangeMixin = {
    name: 'languageChangeMixin',
    data() {
        return {
            currentLangcode: null,
        }
    },
    computed: {
        $currentLangcode() {
            return this.currentLangcode;
        },
        $currentLanguage() {
            return Weglot.getLanguageName(this.currentLangcode);
        },
        $isRtl() {
            switch(this.currentLangcode){
                case "ar":
                return true;
                break;
                case "ku":
                return true;
                break;
                case "ur":
                return true;
                break;
                case "ps":
                return true;
                break;
                case "he":
                return true;
                break;
            }
            return false;
        },
        $isLtr() {
            return !this.$isRtl;
        }
    },
    methods: {
        handleLanguageChange(newLang) {
            this.currentLangcode = newLang;
            if(this.$isLtr){
                document.body.setAttribute('dir','ltr');
            }
            else{
              document.body.setAttribute('dir','rtl');              
          }
      },
      $screenDestroyListener() {
        Weglot.off("languageChanged", this.handleLanguageChange); 
    }
},
mounted() {
    this.currentLangcode = Weglot.getCurrentLang();
    Weglot.on("languageChanged", this.handleLanguageChange); 
},
destroyed() {
    Weglot.off("languageChanged", this.handleLanguageChange); 
}
}
