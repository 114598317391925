<template>
    <div id="app" :class="layoutClasses">
        <AppHeader />
        <notifications position="center right" classes="kkk-notification"/>
        <router-view />
        <AppFooter />
        <WBFeeback :showModal="showFeebackModal" @on-close="modalOnClose" />
        <collection-modal v-if="modalMerkliste" :content="content" @showCreateModal="showCreateModal" @closeModal="modalMerkliste=false, content=null"/>
        <create-collection-modal v-if="modalCreatePlan" :content="content" @closeModal="modalCreatePlan=false, content=null"/>
        <CookieBanner />
        <BackToTop />
    </div>
</template>

<script>
import { languageChangeMixin } from '@/mixins/languageChangeMixin';
import { bus } from './main';

export default {
    name: 'App',
    components: {
        AppHeader: () => import('@/components/layout/AppHeader.vue'),
        AppFooter: () => import('@/components/layout/AppFooter.vue'),
        WBFeeback: () => import('@/components/modals/WBFeedback.vue'),
        CookieBanner: () => import('@/components/modals/CookieBanner.vue'),
        BackToTop: () => import('@/components/controls/BackToTop.vue'),
        CollectionModal: () => import('@/components/modals/addContentToCollection/collection.vue'),
        CreateCollectionModal: () => import('@/components/modals/createCollection'),
    },
    mixins: [languageChangeMixin],
    data: () => ({
        showFeebackModal: false,
        modalMerkliste: false,
        modalCreatePlan: false,
    }),
    created() {
        bus.$on('show-merkliste-modal', (elem) => {
            if(elem!== null && elem.hasOwnProperty("content")){
              this.content = elem.content;
          }
          this.modalMerkliste = true
      })

        bus.$on('show-create-plan-modal', () => {
            this.modalCreatePlan = true
        })
        bus.$on('show-feedback-modal', () => {
            this.showFeebackModal = true;
        });
    },
    computed: {
        layoutClasses() {
          const routeName = this.$route.name;
          return this.camelCaseToHyphen(routeName);
      },
  },
  beforeCreate(){
            /*load all the data needed to run this app*/
    this.$store.dispatch('loadCollections',true);
},
methods: {
    camelCaseToHyphen(str) {
        if(str !== null){
          return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
      }
      return str;
  },
  modalOnClose() {
    this.showFeebackModal = false;
},
showCreateModal () {
    this.modalMerkliste = false
    this.modalCreatePlan = true
},
}
}
</script>
<style lang="scss">
@import '@/scss/_variables.scss';

.vue-notification-group{
    z-index: 12000;
    margin-top: 20px;

    .vue-notification-wrapper{

        .kkk-notification{
          margin: 0px 10px 10px 10px;
          padding: 10px 30px;
          color: $primary;
          border: 1px solid black;
          box-shadow: 1px 2px 4px 0px #00000040;
          transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

          .notification-title{
            color: $secondary;
        }

        .notification-content{
            color: $primary;
        }

        &.error {
            border: 2px solid $secondary;
        }

    }
}
}
</style>