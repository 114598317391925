import request from '@/utils/request';

export function register(data) {
  return request({
    url: 'auth/register',
    method: 'post',
    data: data,
  });
}

export function login(data) {
  return request({
    url: 'auth/login',
    method: 'post',
    data: data,
  });
}

export function logout() {
  return request({
    url: 'auth/logout',
    method: 'post',
  });
}

export function csrf() {
  return request({
    url: '/sanctum/csrf-cookie',
    method: 'get',
  });
}

export function emailExists(email) {
  return request({
    url: '/user/email-exists',
    method: 'post',
    data: email,
  });
}

export function applyResetPassword(data) {
  return request({
    url: '/users/get-password-reset',
    method: 'post',
    data: data,
  });
}

export function resetPassword(data) {
  return request({
    url: '/users/reset-password',
    method: 'post',
    data: data,
  });
}

export function setNewPassword(data) {
  return request({
    url: '/users/set-new-password',
    method: 'post',
    data: data,
  });
}

export function getUser() {
  return request({
    url: '/user',
    method: 'get',
  });
}

export function verify(token) {
  return request({
    url: '/users/verify/'+token,
    method: 'get',
  });
}

